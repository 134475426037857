<template>
  <ion-page>
    <Header type="0" title="问题查看"  v-if="menuRole == 'phone'"> </Header>
    <ion-content id="taskMain" :scroll-y="true">
      <div class="searchBox" slot="fixed">
        <div class="searchItem" @click="selectTypeHandler(0)">
          <p
            :class="targetIndex == 0 ? 'searchName targetIndex' : 'searchName'"
          >
            在装
          </p>
          <div
            class="searchSelect"
            v-show="targetIndex == 0"
            :style="{ width: ('全部'.length * 27) / 16 + 'rem' }"
          ></div>
        </div>
        <div class="searchItem" @click="selectTypeHandler(1)">
          <div
            class="searchSelect"
            v-show="targetIndex == 1"
            :style="{ width: ('负责人'.length * 27) / 16 + 'rem' }"
          ></div>
          <p
            :class="targetIndex == 1 ? 'searchName targetIndex' : 'searchName'"
          >
            结束
          </p>
        </div>
      </div>
      <div class="dataList" id="taskPageList">
        <!-- <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher> -->
        <div class="card-content" @click="openTaskInfo(item)" v-for="(item,idx) in stageList" :key="idx">
          <div class="card-head">
            <span class="stage">{{item.stageCode}}</span>
            <span class="news">{{item.unRead}}</span>
          </div>
          <div class="countList">
            <div>
              <span class="tile">未解决</span>
              <span class="num" style="color:#EA3C54">{{item.unresolved}}</span>
            </div>
            <div>
              <span class="tile">解决中</span>
              <span class="num" style="color:#f38467">{{item.underSolution}}</span>
            </div>
            <div>
              <span class="tile">已解决</span> 
              <span class="num" style="color:#63df95">{{item.resolved}}</span>
            </div>
            <div>
              <span class="tile">让步</span> 
              <span class="num" style="color:#9e9ef2">{{item.compromise}}</span>
            </div>
          </div>
        </div>
        <!-- <ion-infinite-scroll threshold="50px" @ionInfinite="nextPage($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
          ></ion-infinite-scroll-content>
        </ion-infinite-scroll> -->
        <!-- 无数据  -->
        <div class="no_data" v-if="stageList.length <= 0 && !islist">
          <img
            class="no_data_img no_data_top"
            src="@/assets/images/notDate/notSearch.png"
            alt
          />
          <p class="no_data_text">未查询到信息</p>
        </div>
        <CardLoading
          v-if="stageList.length <= 0 && islist"
          :isHome="false"
          lodingType="1"
        ></CardLoading>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  menuController,
  popoverController,
  modalController,
} from "@ionic/vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import questionCard from "./questionCard.vue";
import Header from "@/components/Header.vue";
import {getStatistic} from "@/api/questionManage/selectStage"
import * as dd from "dingtalk-jsapi";
export default {
  name: "question",
  components: {
    IonPage,
    IonContent,
    menuController,
    popoverController,
    modalController,
    Header,
    questionCard,
    CardLoading,
  },
  data() {
    return {
      targetIndex: 0,
      showNoDate: false, //展示暂无数据
      islist: false, //是否显示加载中的标识
      stageList: [],
      selSeg: 0,
      menuRole:'phone'
    };
  },
  mounted(){
     this.menuRole = localStorage.getItem("menuRole");
     if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "问题查看", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
    this.getList()
  },
  methods: {
    getList(){
      this.islist=true
      getStatistic({isLoading:this.targetIndex==0?1:0,isEnd:this.targetIndex==1?1:0,}).then(res=>{
         this.islist=false
        this.stageList = res.data.data.resultData
      })
    },
    selectTypeHandler(type) {
      this.targetIndex = type;
      this.getList()
    },
    openTaskInfo(item) {
      this.$router.push({
        path: "/question",
        query:{
          stageCode:item.stageCode,
          isLoading:this.targetIndex==0?1:0,
          isEnd:this.targetIndex==1?1:0
        }
      });
    },
    nextPage() {},
    change() {},
  },
};
</script>
<style lang="scss" scoped>
#taskMain {
  .searchBox {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    padding: 0 px2rem(10);
    display: flex;
    justify-content: space-around;
    color: #3b3b47;
    font-size: $navigation-font-size;
    background: $default-background;
    .searchItem {
      width: 26%;
      display: flex;
      justify-content: center;
      padding: 0 px2rem(8);
      position: relative;
    }
    .searchItem1 {
      width: 22%;
    }
    .order-select {
      color: $font-segment-title-color;
    }
    p {
      font-size: 14px;
    }
    .ver-line {
      width: px2rem(2);
      display: flex;
      div {
        width: px2rem(2);
        background-color: #c0c0c0;
        height: px2rem(28);
        margin: auto;
      }
    }
    .targetIndex {
      color: $font-segment-title-color;
    }
    .searchLine {
      height: px2rem(30);
      border-right: 0.01rem solid #e4ebf0;
      margin: px2rem(25) 0;
    }
    .searchSelect {
      position: absolute;
      // left: calc(50% - #{px2rem(30)});
      bottom: 0.7rem;
      width: px2rem(60);
      height: px2rem(2);
      background: $font-segment-title-color;
    }
    .iconfont {
      margin-left: px2rem(10);
      font-size: $so-very-little-font-size;
      width: px2rem(25);
    }
  }
  .allTarget {
    margin-top: px2rem(78);
    width: 100%;
    background: $page-background;
  }
  .segment {
    background: $page-background;
    height: 4.5rem;
    .segment_button {
      --padding-start: 1.3rem;
      --padding-end: 1.3rem;
      --padding-top: 0;
      --padding-bottom: 0;
      margin: 0 px2rem(8);
      min-height: px2rem(54);
    }

    .segment-button-checked {
      background: #ffffff;
      color: $color;
    }
  }
  .categoryBox {
    display: flex;
    justify-content: space-around;
    overflow-x: scroll;
    margin: px2rem(20) px2rem(20) px2rem(10);
    .category {
      padding: px2rem(11) px2rem(22);
      font-size: $default-font-size;
      border-radius: 2rem;
      background: #fff;
    }
    .categorySelect {
      background: #f38467;
      color: #fff;
    }
  }
  ion-refresher {
    margin-top: px2rem(100);
  }
  .dataList {
    margin-top: px2rem(100);
  }
}
.title {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: bold;
  color: $color;
}
.selectOption {
  width: 100%;
  padding: 10px 12px 10px 27px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    ion-checkbox {
      margin-right: 11px;
    }
    .questionTitle {
      font-size: 13px;
      font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
      font-weight: 700;
      color: #333333;
    }
  }
  > div:nth-of-type(1) {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  > div:nth-of-type(2) {
    font-size: 11px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: bold;
    text-align: left;
    color: #333333;
    span {
      color: #ed910e;
    }
  }
  ion-button {
    --background: #ed910e;
    --padding-start: 58px;
    --padding-end: 58px;
    --background-activated: gray;
  }
}
ion-fab-button {
  --background: #0e67ed;
  // #003ca7
}
.card-content {
  width: calc(100% - 24px);
  padding: 10px 15px;
  margin: 10px auto 0;
  background: #ffffff;
  border-radius: 5px;
  .card-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .stage {
      font-size: 17px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #1b2749;
    }
    .news {
      font-size: 17px;
      font-family: SourceHanSansCN, SourceHanSansCN-Medium;
      font-weight: 500;
      color: #409eff;

    }
  }
  .countList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    > div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      margin: 0 -10px;
      .tile {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #979797;
      }
      .num {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
      }
    }
  }
}
</style>